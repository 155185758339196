import service from '../services';

/**
 * @param dadosConsultaPlaca {JSON} - objeto com os dados da consulta placa, idEmpresa, permitePlacaRepetida, propostaParaSomenteAgregado
 * @returns - objeto com dados da consulta
 */
export async function validaPlacaEmAberto(dadosConsultaPlaca) {
  if (dadosConsultaPlaca.propostaParaSomenteAgregado) {
    return true;
  } ;

  try {
    if (dadosConsultaPlaca.permitePlacaRepetida == false) {
      const res = await service.post(
        'CotacaoAberta',
        'validaPlaca',
        dadosConsultaPlaca,
      );

        const nomeConsultorPlaca = res.data.consultor;
        const regionalConsultorPlaca = res.data.regional;
        const modalPlacaRecusada = true;
        const codigoSituacao = res.data.codigoSituacao;

        return {
          nomeConsultorPlaca,
          regionalConsultorPlaca,
          modalPlacaRecusada,
          codigoSituacao,
        };
    }
  } catch (error) {
    throw new Error('Falha ao validar a placa, tente novamente');
  }
  return true;
}

export function checkTipoPlaca(placa) {
  if (placa || typeof placa == 'string') {
    const _placa = placa.toLocaleUpperCase();
    let tipoPlaca;
    const padraoPlacaMercoSul =
      /([A-Z]{3})([-]{0,1})([0-9]{1}[A-J]{1}[1-9]{2})/g;
    const padraoPlacaBr = /([A-Z]{3})([-]{0,1})([0-9]{4})/g;

    const placaMercosul = padraoPlacaMercoSul.test(_placa);
    if (placaMercosul) {
      tipoPlaca = 'Mercosul';
      return tipoPlaca;
    }

    const placaBr = padraoPlacaBr.test(_placa);
    if (placaBr) {
      tipoPlaca = 'Placa Br';
      return tipoPlaca;
    }
  }

  this.$vs.notify({
    position: 'top-center',
    title: 'Atenção',
    text: 'A placa não é valida',
    color: 'danger',
  });

  return 'Placa invalida';
}

export function preparaPlacaParaConsulta(placa) {
  const arrayCaracteresPlaca = Array.from(placa.toUpperCase());
  const temHiffen = arrayCaracteresPlaca.includes('-');
  const objCaracteresPlaca = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'I',
    9: 'J',
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    E: 4,
    F: 5,
    G: 6,
    H: 7,
    I: 8,
    J: 9,
  };

  const indexCaracter = temHiffen ? 5 : 4;
  const caracterSubstiruicao = arrayCaracteresPlaca[indexCaracter];
  arrayCaracteresPlaca[indexCaracter] =
    objCaracteresPlaca[caracterSubstiruicao];
  const placaAlternativa = arrayCaracteresPlaca.join('');

  return [placa, placaAlternativa];
}

export async function consultaPlaca({
  placa,
  idEmpresa,
  somenteAgregado,
  permitePlacaRepetida,
}) {
  if (!placa) {
    return;
  }

  if (placa.length == 8) {
    try {
      const _arrPlacas = preparaPlacaParaConsulta(placa);

      const dadosValidaPlaca = await validaPlacaEmAberto({
        placa,
        placas: _arrPlacas,
        idEmpresa,
        propostaParaSomenteAgregado: somenteAgregado,
        permitePlacaRepetida: permitePlacaRepetida,
      });

      if (dadosValidaPlaca.codigoSituacao == '999') {
        return dadosValidaPlaca;
      }

      return dadosValidaPlaca;
    } catch (error) {
      throw new Error(
        'Falha ao buscar placa, tente novamente a internet pode estar instavel',
      );
    }
  }
}

export async function consultarPlacaChassiBaseBP(dadosConsulta) {
  try {
    const res = await service.post(
      'CotacaoAberta',
      'validaPlacaChassiBP',
      dadosConsulta,
    );

    const codigoSituacao = res.data.codigoSituacao;
    const situacao = res.data.situacao;
    const modalPlacaRecusada = codigoSituacao === '998' ? true : false;

    return Promise.resolve({
      modalPlacaRecusada,
      codigoSituacao,
      situacao,
    });
  } catch (error) {
    return Promise.reject(error);
  }
}
