<template>
  <div>
    <money
      v-model="novoValorProduto"
      v-bind="money"
      class="form-control"
      name="Valor do Produto"
      id="ValorProduto"
      v-validate="{ required: true, min_value: valorProdutoOriginal }"
      :state="validateCommun('S','Valor do Produto')"
      :danger="validateCommun('C','Valor do Produto')"
    ></money>
    <button
          style="width: 100%"
          type="button"
          @click="processarNovoValor"
          class="btn btn-success mt-4"
        >
          Alterar Valor
        </button>
  </div>
</template>
<script>
import { Money } from 'v-money';
import utils from '../services/utils.js';

export default {
  name: 'FormulárioNovoValorProduto',
  props: ['produto'],
  components: {
    Money,
  },
  data() {
    const money = {
      decimal: '.',
      thousands: '',
      prefix: '',
      suffix: '',
      precision: 2,
    };
    return {
      money,
      novoValorProduto: 0,
      valorProdutoOriginal: 0,
      validateClass: utils.validateClass,
      validateState: utils.validateState,
      notify: utils.showToast,
    };
  },
  mounted() {
    this.preencheDados();
  },
  computed: {},
  methods: {
    validateCommun(tipo, campo) {
      tipo === 'C' && this.validateClass(this.veeFields, this.veeErrors, campo);
      tipo === 'S' && this.validateState(this.veeFields, this.veeErrors, campo);
    },
    async validate() {
      const validado = await this.$validator.validateAll();
      this.$validator.errors
        .all()
        .map((error) => this.notify(this.$bvToast, error, 'danger'));
      return validado;
    },
    preencheDados() {
      this.produtoModificado = this.produto;
      const { valor, valorOriginal } = this.produto;
      this.novoValorProduto = valor;
      this.valorProdutoOriginal = valorOriginal || valor;
      this.produtoModificado.valorOriginal = this.valorProdutoOriginal;
    },
    parsear(valor) {
      return parseFloat(
        valor.slice(3, valor.length).replace('.', '').replace(',', '.'),
      );
    },
    async processarNovoValor() {
      const validado = await this.validate();
      if (validado) {
        this.reatribuirNovoValorProduto();
        this.recalculadesconto();
        this.$emit('close');
      }
    },
    reatribuirNovoValorProduto() {
      if (typeof this.novoValorProduto === 'string') {
        this.novoValorProduto = this.parsear(this.novoValorProduto);
      }

      this.produtoModificado.valor = this.novoValorProduto;
    },
    recalculadesconto() {
      const { valor, descontoConcedido } = this.produtoModificado;
      const novoValorDesconto = valor * (descontoConcedido / 100);
      const novoValorFinal = valor * ((100 - descontoConcedido) / 100);

      this.produtoModificado.valorDesconto = novoValorDesconto;
      this.produtoModificado.valorFinal = novoValorFinal;
    },
  },
};
</script>
<style></style>
