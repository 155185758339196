<template>
  <div class="FormAgregado">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <b-row>
        <b-col>
          <div class="container mt-3">
            <b-form @submit.stop.prevent="onSubmit" class="text-left">
              <b-form-group label="Descrição do agregado" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.descricaoAgregado"
                  placeholder="Descrição do agregado"
                  name="Descrição do agregado"
                  v-validate="{ required: true }"
                  :state="validateState('Descrição do agregado')"
                  :class="validateClass('Descrição do agregado')"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Placa de vínculo"
                label-for="input-2"
                v-if="formCompleto"
              >
                <the-mask
                  mask="AAA-#XX#"
                  style="text-transform: uppercase"
                  id="input-2"
                  class="form-control"
                  v-model="form.placaVinculo"
                  placeholder="Placa de vínculo"
                  name="Placa de vínculo"
                  :masked="true"
                  v-validate="{ required: true, length: 8 }"
                  :state="validateState('Placa de vínculo')"
                />
              </b-form-group>
              <b-form-group
                label="Chassi do vínculo"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-input
                  id="input-2"
                  style="text-transform: uppercase"
                  v-model="form.chassiVinculo"
                  placeholder="Chassi do vínculo"
                  name="Chassi do vínculo"
                  maxlength="17"
                  v-validate="{ required: true, length: 17 }"
                  :state="validateState('Chassi do vínculo')"
                  :class="validateClass('Chassi do vínculo')"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Marca do Agregado" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.agregadoMarca"
                  placeholder="Marca do Agregado"
                  name="Marca do Agregado"
                  :state="validateState('Marca do Agregado')"
                  :class="validateClass('Marca do Agregado')"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Modelo do Agregado" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.agregadoModelo"
                  placeholder="Modelo do Agregado"
                  name="Modelo do Agregado"
                  :state="validateState('Modelo do Agregado')"
                  :class="validateClass('Modelo do Agregado')"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Valor de Cobertura"
                label-for="input-2"
                v-if="!escondeCobertura == true"
              >
                <money
                  class="form-control"
                  name="Valor de Cobertura"
                  id="Valor de Cobertura"
                  placeholder="Valor da Nota"
                  v-model="form.valorCobertura"
                  v-validate="{ required: true, min_value: 0.01 }"
                  :state="validateState('Valor de Cobertura')"
                  :danger="validateClass('Valor de Cobertura')"
                  v-bind="money"
                  :disabled="usarValorNf"
                ></money>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                v-if="formCompleto && !escondeCobertura == true"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="usarValorNf"
                  switch
                  >Substituir pelo valor da NF</b-form-checkbox
                >
              </b-form-group>

              <b-form-group
                v-if="usarValorNf"
                id="input-group-2"
                label="Data da NF"
                label-for="input-2"
              >
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="form.dataNF"
                  v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
                  placeholder="Data da Nota Fiscal"
                  mask="##/##/####"
                  id="dataNascimento"
                  name="Data da Nota Fiscal"
                  :masked="true"
                  :state="validateState('Data da Nota Fiscal')"
                  :class="validateClass('Data da Nota Fiscal')"
                ></the-mask>
              </b-form-group>
              <b-form-group
                v-if="usarValorNf"
                id="input-group-2"
                label="Número da NF"
                label-for="input-2"
              >
                <b-form-input
                  type="number"
                  id="input-2"
                  v-model="form.numeroNF"
                  name="Nota Fiscal"
                  placeholder="Número da Nota fiscal"
                  v-validate="{ required: true }"
                  :state="validateState('Nota Fiscal')"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="usarValorNf"
                id="input-group-2"
                label="Valor da NF"
                label-for="input-2"
              >
                <money
                  class="form-control"
                  name="Valor da Nota Fiscal"
                  id="Valor da Nota Fiscal"
                  placeholder="Valor da Nota"
                  v-model="form.valorNF"
                  v-validate="{ required: true }"
                  :state="validateState('Valor da Nota Fiscal')"
                  :danger="validateClass('Valor da Nota Fiscal')"
                  v-bind="money"
                ></money>
              </b-form-group>

              <b-button
                pill
                ref="btnAvancar"
                size="lg"
                type="submit"
                variant="info"
                class="btn btn-block mb-5"
                :style="`background-color:${temaCor.botaoFormularios};`"
                >Salvar</b-button
              >
            </b-form>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import service from '../services';
import moment from 'moment';
import locale from './../locale';
import { Money } from 'v-money';
export default {
  name: 'DashBoard',
  props: {
    temaCor: Object,
    formCompleto: Boolean,
    idAgregado: Number,
    dados: Object,
    contModal: Number,
    escondeCobertura: Boolean,
  },
  watch: {
    dados() {
      this.preencheForm();
    },
    contModal() {
      this.preencheForm();
    },
  },
  data() {
    return {
      usarValorNf: false,
      decoded: {},
      money: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
      },
      menuOpen: false,
      carregando: false,
      placeHolderCpf: 'Cpf',
      maskCpf: '###.###.###-##',
      modelos: [],
      modelosAux: [],
      form: {
        descricaoAgregado: null,
        chassiVinculo: null,
        placaVinculo: null,
        agregadoMarca: null,
        agregadoModelo: null,
        valorCobertura: 0,
        numeroNF: null,
        valorNf: 0,
        dataNF: null,
      },
    };
  },
  components: { Money },
  methods: {
    formataStringData(data) {
      var dia = data.split('/')[0];
      var mes = data.split('/')[1];
      var ano = data.split('/')[2];

      //return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
      return [ano, mes, dia];
      // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit(evt) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }

        if (this.idAgregado) {
          this.carregando = true;
          service
            .put(
              'Cotacao',
              'AtualizarAgregado/' + this.idAgregado,
              this.form,
              {},
            )
            .then((res) => {
              this.$bvToast.toast('Dados Atualizados com sucesso', {
                title: 'Atenção!',
                solid: true,
                variant: 'success',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 2500,
              });
              this.carregando = false;
              this.$emit('concluido', this.form);
            })
            .catch((e) => {
              this.carregando = false;
              this.$bvToast.toast(e, {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 2500,
              });
            });
        } else {
          this.$emit('concluido', this.form);
        }
      });
    },
    preencheForm() {
      this.form.descricaoAgregado = this.dados.descricaoAgregado;
      this.form.chassiVinculo = this.dados.chassiVinculo;
      this.form.placaVinculo = this.dados.placaVinculo;
      this.form.agregadoMarca = this.dados.agregadoMarca;
      this.form.agregadoModelo = this.dados.agregadoModelo;
      this.form.valorCobertura = this.dados.valorCobertura;
      this.form.numeroNF = this.dados.numeroNF;
      this.form.valorNf = this.dados.valorNf;
      this.form.dataNF = this.dados.dataNF
        ? moment(this.dados.dataNF).utc().format('DD/MM/YYYY')
        : null;
    },
  },
  mounted() {
    //this.verificaToken();
    this.preencheForm();
    this.$validator.localize('en', locale);
  },
};
</script>

<style lang="scss" scoped>
.FormAgregado {
  padding-bottom: 40px;
  label,
  label.d-block,
  .form-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'robotobold';
  }
}
</style>
