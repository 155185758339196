<template>
  <div class="FormImplemento">
    <div>
      <div v-if="carregando == true" class="carregando">
        <!--img
          width="100"
          src="https://bboneapp.s3.amazonaws.com/evo-white.png"
        /-->
        <br />
        <br />
        <br />Carregando.. Aguarde
      </div>
      <b-row>
        <b-col>
          <div class="container mt-3">
            <b-form @submit.stop.prevent="onSubmit" class="text-left">
              <!-- Tipo de Complemento -->
              <b-form-group id="input-group-2" label="Tipo" label-for="input-2">
                <b-form-select
                  :options="tipos"
                  :value="form.tipo"
                  v-model="form.tipo"
                  name="Tipo"
                  v-validate="{ required: true }"
                  :state="validateState('Tipo')"
                  :class="validateClass('Tipo')"
                ></b-form-select>
              </b-form-group>

              <!-- Grupo do agregado -->
              <b-form-group
                id="Grupo do agregado"
                label="Grupo do agregado"
                label-for="Grupo do agregado"
                v-if="form.tipo === 'agregado' && !desabilitarEdicaoGrupo"
              >
                <b-form-select
                  :options="grupos"
                  v-model="idGrupo"
                  name="Grupo do agregado"
                  value-field="id"
                  text-field="label"
                  v-validate="{ required: true }"
                  :state="validateState('Grupo do agregado')"
                  :class="validateClass('Grupo do agregado')"
                  @change="selecionaGrupoAgregado()"
                  :disabled="desabilitarEdicaoGrupo"
                ></b-form-select>
              </b-form-group>

              <!-- Descrição -->
              <b-form-group
                :label="`Descrição do ${form.tipo}`"
                label-for="descrição"
              >
                <b-form-input
                  id="descrição"
                  v-model="form.descricao"
                  :placeholder="`Descreva o ${form.tipo}`"
                  name="descrição"
                  v-validate="{ required: true }"
                  :state="validateState('descrição')"
                  :class="validateClass('descrição')"
                ></b-form-input>
              </b-form-group>

              <!-- placa -->
              <b-form-group
                id="input-group-2"
                :label="`Placa do ${form.tipo}`"
                label-for="input-2"
              >
                <the-mask
                  mask="AAA-#XX#"
                  style="text-transform: uppercase"
                  id="input-2"
                  class="form-control"
                  v-model="form.placa"
                  placeholder="Placa"
                  name="Placa"
                  :masked="true"
                  v-validate="{ length: 8 }"
                  :state="validateState('Placa')"
                />
              </b-form-group>

              <!-- chassi -->
              <b-form-group
                :label="`Chassi do ${form.tipo}`"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  style="text-transform: uppercase"
                  v-model="form.chassi"
                  placeholder="Chassi"
                  name="Chassi"
                  maxlength="17"
                  v-validate="{ length: 17 }"
                  :state="validateState('Chassi')"
                  :class="validateClass('Chassi')"
                ></b-form-input>
              </b-form-group>

              <!-- Estrutura -->
              <!-- <b-form-group
                id="input-group-2"
                label="Estrutura"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-select
                  v-model="form.tipoEstrutura"
                  :options="tipoEstrutura"
                  name="Estrutura"
                  v-validate="{ required: true }"
                  :state="validateState('Estrutura')"
                  :class="validateClass('Estrutura')"
                ></b-form-select>
              </b-form-group> -->

              <!-- Marca -->
              <b-form-group
                :label="`Marca do ${form.tipo}`"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.marca"
                  placeholder="Marca"
                  name="Marca"
                  v-validate="{ required: true }"
                  :state="validateState('Marca')"
                  :class="validateClass('Marca')"
                ></b-form-input>
              </b-form-group>

              <!-- Modelo -->
              <b-form-group
                :label="`Modelo do ${form.tipo}`"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.modelo"
                  placeholder="Modelo"
                  name="Modelo"
                  :state="validateState('Modelo')"
                  :class="validateClass('Modelo')"
                ></b-form-input>
              </b-form-group>

              <!-- Valor de Cobertura -->
              <b-form-group
                id="input-group-2"
                :label="`Valor de Cobertura do ${form.tipo}`"
                label-for="input-2"
                v-if="!escondeCobertura == true"
              >
                <money
                  class="form-control"
                  name="Valor de Cobertura"
                  id="Valor de Cobertura"
                  placeholder="Valor da Nota"
                  v-model="form.valorCobertura"
                  v-validate="{ required: true, min_value: 0.01 }"
                  :state="validateState('Valor de Cobertura')"
                  :danger="validateClass('Valor de Cobertura')"
                  v-bind="money"
                  :disabled="usarValorNf"
                ></money>
              </b-form-group>

              <!-- valor da NF -->
              <b-form-group
                id="input-group-4"
                v-if="formCompleto && !escondeCobertura == true"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="usarValorNf"
                  switch
                  >Substituir pelo valor da NF</b-form-checkbox
                >
              </b-form-group>

              <!-- Data da NF -->
              <b-form-group
                v-if="usarValorNf"
                id="input-group-2"
                label="Data da NF"
                label-for="input-2"
              >
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="form.dataNF"
                  v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
                  placeholder="Data da Nota Fiscal"
                  mask="##/##/####"
                  id="dataNascimento"
                  name="Data da Nota Fiscal"
                  :masked="true"
                  :state="validateState('Data da Nota Fiscal')"
                  :class="validateClass('Data da Nota Fiscal')"
                ></the-mask>
              </b-form-group>

              <!-- Número da NF -->
              <b-form-group
                v-if="usarValorNf"
                id="input-group-2"
                label="Número da NF"
                label-for="input-2"
              >
                <b-form-input
                  type="number"
                  id="input-2"
                  v-model="form.numeroNF"
                  name="Nota Fiscal"
                  placeholder="Número da Nota fiscal"
                  v-validate="{ required: true }"
                  :state="validateState('Nota Fiscal')"
                ></b-form-input>
              </b-form-group>

              <!-- Valor da NF -->
              <b-form-group
                v-if="usarValorNf"
                id="input-group-2"
                label="Valor da NF"
                label-for="input-2"
              >
                <money
                  class="form-control"
                  name="Valor da Nota Fiscal"
                  id="Valor da Nota Fiscal"
                  placeholder="Valor da Nota"
                  v-model="form.valorNF"
                  v-validate="{ required: true }"
                  :state="validateState('Valor da Nota Fiscal')"
                  :danger="validateClass('Valor da Nota Fiscal')"
                  v-bind="money"
                ></money>
              </b-form-group>

              <!-- Ano de Fabricação -->
              <!-- <b-form-group
                label="Ano de Fabricação"
                label-for="input-2"
                v-if="formCompleto"
              >
                <the-mask
                  type="text"
                  class="form-control"
                  v-model="form.anoFabricacao"
                  v-validate="{ min: 4, required: true, date_format: 'yyyy' }"
                  placeholder="Ano de Fabricação"
                  mask="####"
                  id="anoFabricacao"
                  name="Ano de Fabricação"
                  :masked="true"
                  :state="validateState('Ano de Fabricação')"
                  :class="validateClass('Ano de Fabricação')"
                ></the-mask>
              </b-form-group> -->

              <!-- Cor predominante -->
              <!-- <b-form-group
                label="Cor predominante"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.corPredominante"
                  placeholder="Cor predominante"
                  name="Cor predominante"
                  :state="validateState('Cor predominante')"
                  :class="validateClass('Cor predominante')"
                ></b-form-input>
              </b-form-group> -->

              <!-- Quantidade de Eixo -->
              <!-- <b-form-group
                label="Quantidade de Eixo"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="form.quantidadeEixo"
                  min="0"
                ></b-form-spinbutton>
              </b-form-group> -->

              <!-- Quantidade de Pneus -->
              <!-- <b-form-group
                label="Quantidade de Pneus"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="form.quantidadePneus"
                  min="0"
                ></b-form-spinbutton>
              </b-form-group> -->

              <!-- Comprimento em Metros -->
              <!-- <b-form-group
                label="Comprimento em Metros"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="form.comprimento"
                  min="0"
                ></b-form-spinbutton>
              </b-form-group> -->

              <!-- Altura em Metros -->
              <!-- <b-form-group
                label="Altura em Metros"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="form.altura"
                  min="0"
                ></b-form-spinbutton>
              </b-form-group> -->

              <!-- Largura em Metros -->
              <!-- <b-form-group
                label="Largura em Metros"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="form.largura"
                  min="0"
                ></b-form-spinbutton>
              </b-form-group> -->

              <!-- Peso Bruto Combinado em Toneladas -->
              <!-- <b-form-group
                label="Peso Bruto Combinado em Toneladas"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="form.PTBC"
                  min="0"
                ></b-form-spinbutton>
              </b-form-group> -->

              <!-- Capac. de Carga em Toneladas -->
              <!-- <b-form-group
                label="Capac. de Carga em Toneladas"
                label-for="input-2"
                v-if="formCompleto"
              >
                <b-form-spinbutton
                  id="demo-sb"
                  v-model="form.capacidadeCarcaTon"
                  min="0"
                ></b-form-spinbutton>
              </b-form-group> -->

              <b-form-group label="Observação" label-for="input-2">
                <b-form-textarea
                  id="textarea"
                  v-model="form.observacao"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-button
                pill
                ref="btnAvancar"
                size="lg"
                type="submit"
                variant="info"
                class="btn btn-block mb-5"
                :style="`background-color:${temaCor.botaoFormularios};`"
                >Salvar</b-button
              >
            </b-form>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import service from '../services';
import moment from 'moment';
import locale from '../locale';
import { Money } from 'v-money';

export default {
  name: 'FormComplemento',
  props: {
    temaCor: Object,
    formCompleto: Boolean,
    idComplemento: Number,
    dados: Object,
    contModal: Number,
    escondeCobertura: Boolean,
    tipoComplemento: String,
    grupos: Array,
    desabilitarEdicaoGrupo: Boolean,
  },
  watch: {
    dados() {
      this.preencheForm();
    },
    contModal() {
      this.preencheForm();
    },
    tipoComplemento() {
      this.form.tipo = this.tipoComplemento;
    },
    grupos(value) {
      this.gruposAgregados = this.grupos;
    },
    'form.tipo': function (value) {
      if (value === 'implemento') {
        this.idGrupo = null;
        this.form.idGrupo = null;
      }
    },
  },
  data() {
    return {
      gruposAgregados: [],
      tipos: ['agregado', 'implemento'],
      idGrupo: null,
      usarValorNf: false,
      decoded: {},
      money: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 2,
      },
      menuOpen: false,
      carregando: false,
      placeHolderCpf: 'Cpf',
      maskCpf: '###.###.###-##',
      form: {
        tipo: null,
        idGrupo: null,
        placa: null,
        chassi: null,
        marca: null,
        modelo: null,
        numeroNF: null,
        valorNF: 0,
        dataNF: null,
        observacao: null,
        valorCobertura: 0,
      },
    };
  },
  components: { Money },
  methods: {
    selecionaGrupoAgregado() {
      console.log(497, 'selecionaGrupoAgregado', this.idGrupo);
      const [_grupo] = this.grupos.filter((item) => item.id == this.idGrupo);

      this.form.idGrupo = this.idGrupo;
      this.form.nomeGrupo = _grupo.label;
      this.form.porcentagemParticipacao = _grupo.porcentagemParticipacao;
      this.form.valorMinimoParticipacao = _grupo.valorMinimoParticipacao;
    },
    formataStringData(data) {
      var dia = data.split('/')[0];
      var mes = data.split('/')[1];
      var ano = data.split('/')[2];

      //return ano + "-" + ("0" + mes).slice(-2) + "-" + ("0" + dia).slice(-2);
      return [ano, mes, dia];
      // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    onSubmit(evt) {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.popToast();
          return;
        }

        if (this.idComplemento) {
          service
            .put(
              'Cotacao',
              'AtualizarComplemento/' + this.idComplemento,
              this.form,
              {},
            )
            .then(() => {
              this.$bvToast.toast('Dados Atualizados com sucesso', {
                title: 'Atenção!',
                solid: true,
                variant: 'success',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 2500,
              });
              this.$emit('concluido', this.form);
            })
            .catch((e) => {
              this.$bvToast.toast(e, {
                title: 'Atenção!',
                solid: true,
                variant: 'danger',
                toaster: 'b-toaster-top-full',
                appendToast: false,
                autoHideDelay: 2500,
              });
            });
        } else {
          this.$emit('concluido', this.form);
        }
      });
    },
    preencheForm() {
      this.form.tipo = this.dados.tipo;
      this.form.descricao = this.dados.descricao;
      this.form.placa = this.dados.placa;
      this.form.chassi = this.dados.chassi;
      this.form.marca = this.dados.marca;
      this.form.modelo = this.dados.modelo;
      this.form.idGrupo = this.dados.idGrupo;
      this.idGrupo = this.dados.idGrupo;
      this.form.numeroNF = this.dados.numeroNF;
      this.form.valorNF = this.dados.valorNF;
      this.form.dataNF = this.dados.dataNF
        ? moment(this.dados.dataNF).utc().format('DD/MM/YYYY')
        : null;
      this.form.observacao = this.dados.observacao;
      this.form.valorCobertura = this.dados.valorCobertura;
    },
  },
  mounted() {
    //this.verificaToken();
    this.preencheForm();
    this.form.tipo = this.tipoComplemento;
    console.log(613, this.form.tipo);
    this.$validator.localize('en', locale);
  },
};
</script>

<style lang="scss" scoped>
.FormImplemento {
  padding-bottom: 40px;
  label,
  label.d-block,
  .form-group {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    font-family: 'robotobold';
  }
}
</style>
